<template>
	<div class="jobfair">
		<div class="job-content">
			<div class="Jobfair_title">
				招聘会ing
			</div>
			<div class="recruit">
				<div class="recruit_item" v-for="item in activitydata" :key="item.id"
					@click="goto('/jobfairdetail', { id: item.id })">
					<div class="img"><img :src="setcompanylogo(item.cPicName)" alt="" /></div>
					<div class="img_information">
						<div class="information">
							<div style="margin-bottom:6px;color: rgba(16, 16, 16, 100)">{{ item.cZph }}</div>
							<div>{{ item.cZhuti }}</div>
						</div>
						<div class="sign_up">进行中</div>
					</div>
				</div>
			</div>
			<div class="Jobfair_title">
				招聘会预告
			</div>
			<div class="recruit">
				<div class="recruit_item" v-for="item in activitydata1" :key="item.id"
					@click="goto('/jobfairdetail', { id: item.id })">
					<div class="img"><img :src="setcompanylogo(item.cPicName)" alt="" /></div>
					<div class="img_information">
						<div class="information">
							<div style="margin-bottom:6px;color: rgba(16, 16, 16, 100)">{{ item.cZph }}</div>
							<div>{{ item.cZhuti }}</div>
						</div>
						<div class="sign_up">预告中</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		Getjobfairlist
	} from "@/api/home";
	import utils from "@/assets/js/utils";
	import {
		isnull
	} from "@/assets/js/index";
	import global from "@/assets/js/globalconfig";
	export default {
		data() {
			return {
				defimgurl: global.baseimgurl,
				selfrom: {
					KW: "",
					pageNumber: 1,
					pageSize: 10,
				},
				input: "",
				user: {},
				val: "",
				total: 0,
				activitydata: [],
				activitydata1: [],
				isActive: false,
				scenedata: [],
			};
		},
		created() {
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.user = JSON.parse(localStorage.getItem("userinfo"));
			}
			this.GetList();
			this.GetList1();
		},
		methods: {
			handleSizeChange(val) {
				this.selfrom.pageNumber = 1;
				this.selfrom.pageSize = val;
				this.GetList();
				this.GetList1();
				// this.backtop();
			},
			handleCurrentChange(val) {
				this.selfrom.pageNumber = val;
				// this.GetList();
				// this.backtop();
			},
			setcompanylogo(url) {
				return isnull(url) ?
					require("@/assets/img/activity-1.jpg") :
					this.defimgurl + url;
			},
			goto(url, data) {
				this.resolvewin(url, data, 1);
			},
			GetList() {
				var _this = this;
				_this.selfrom.types = 3;
				_this.selfrom.showLoadType = ".activity-cell";
				Getjobfairlist(_this.selfrom).then((res) => {
					if (res.success) {
						_this.activitydata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			GetList1() {
				var _this = this;
				_this.selfrom.types = 1;
				_this.selfrom.showLoadType = ".activity-cell";
				Getjobfairlist(_this.selfrom).then((res) => {
					if (res.success) {
						_this.activitydata1 = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			enter(cell) {
				this.isActive = true;
				this.num = cell.id;
			},
			leave() {
				this.isActive = false;
			},
			handleSizeChange(val) {
				this.selfrom.pageNumber = val;
				this.selfrom.pageNumber = 1;
				this.GetList();
			},
			handleCurrentChange(val) {
				this.selfrom.pageNumber = val;
				this.GetList();
			},
			zphtype(i) {
				switch (i) {
					case 0:
						return "普通招聘会";
						break;
					case 1:
						return "大型招聘会";
						break;
					case 2:
						return "赴外招聘会";
						break;
					case 3:
						return "直播招聘会";
						break;
					case 99:
						return "专场招聘会";
						break;
					default:
						break;
				}
			},
		},
		computed: {
			listTemp: function() {
				var companydata = this.companydata;
				var arrTemp = [];
				var index = 0;
				var sectionCount = 8;
				for (var i = 0; i < companydata.length; i++) {
					index = parseInt(i / sectionCount);
					if (arrTemp.length <= index) {
						arrTemp.push([]);
					}
					arrTemp[index].push(companydata[i]);
				}
				return arrTemp;
			},
		},
		mounted() {},
	};
</script>
<style lang="scss" scoped>
	.banner {
		width: 100%;
		height: 360px;
		padding: 0;
		margin: 0px;
		margin-bottom: 20px;
		position: relative;
		background: url(../../assets/img/activity.png) top center no-repeat;

		.banner-img {
			width: 100%;
			height: 360px;
			margin: 0px auto;
			padding: 0;
			background: url(../../assets/img/activity.png) top center no-repeat;
		}
	}

	.Jobfair_center {
		width: 1200px;
		margin: 0px auto;
		margin-bottom: 16px;
	}

	.Jobfair_title {
		width: 1200px;
		margin: 0px auto;
		line-height: 50px;
		font-size: 18px;
	}

	.recruit {
		width: 1200px;
		margin: 0px auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		.recruit_item {
			cursor: pointer;
			margin: 5px;
			width: 290px;
			height: 228px;
			background-color: #409eff;

			.img {
				width: 100%;
				height: 158px;
				background: #ff7630;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.img_information {
				width: 100%;
				height: 70px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				background-color: rgba(255, 255, 255, 100);

				.information {
					color: #898989;
					font-size: 14px;
					margin-left: 14px;
				}

				.sign_up {
					width: 80px;
					margin-right: 12px;
					;
					height: 30px;
					border-radius: 4px;
					// background-color: rgba(5, 173, 247, 100);
					color: rgba(5, 173, 247, 100);
					font-size: 14px;
					text-align: center;
					font-family: Microsoft Yahei;
					border: 1px solid rgba(5, 173, 247, 100);
					line-height: 30px;
				}
			}
		}
	}
</style>
